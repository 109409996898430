import React from "react"
function CloseButton({ isOpen, onClick }) {
  return (
    <button
      onClick={onClick}
      css={{
        position: "absolute",
        left: 15,
        top: "50%",
        transform: "translateY(-50%)",
        border: "none",
        outline: "none",
        background: "transparent",
        cursor: "pointer",
        width: 25,
        height: 25,
        padding: 0,
        "@media(min-width: 990px)": {
          display: "none",
        },
      }}
    >
      <span
        css={{
          position: "absolute",
          width: 1,
          height: 1,
          padding: 0,
          margin: -1,
          overflow: "hidden",
          clip: "rect(0,0,0,0)",
          border: 0,
        }}
      >
        toggle navigation
      </span>
      <span
        css={{
          backgroundColor: "#562c36",
          position: "relative",
          width: 15,
          height: 1,
          display: "inline-block",
          background: isOpen ? "transparent" : "#34343c",
          transitionDuration: "400ms",
          transitionProperty: "all",
          transitionTimingFunction: "cubic-bezier(0.7,1,0.7,1)",
          "&::before": {
            background: "#34343c",
            position: "absolute",
            left: 0,
            width: 15,
            height: 1,
            content: '" "',
            bottom: isOpen ? 5 : 10,
            transform: `rotate(${isOpen ? 45 : 0}deg)`,
            transitionDuration: "400ms",
            transitionProperty: "all",
            transitionTimingFunction: "cubic-bezier(0.7,1,0.7,1)",
          },
          "&::after": {
            background: "#34343c",
            position: "absolute",
            left: 0,
            width: 15,
            height: 1,
            content: '" "',
            top: -5,
            transform: `rotate(${isOpen ? -45 : 0}deg)`,
            transitionDuration: "400ms",
            transitionProperty: "all",
            transitionTimingFunction: "cubic-bezier(0.7,1,0.7,1)",
          },
        }}
      />
    </button>
  )
}

export default CloseButton
