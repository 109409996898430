import React from "react"
import logo from "../images/lotusclinic-logo.svg"
import CloseButton from "./close-button"
import Navigation from "./navigation"
function Header() {
  const [isOpen, setIsOpen] = React.useState(false)
  return (
    <header
      css={{
        position: "fixed",
        backgroundColor: "rgba(251, 248, 243, 0.7)",
        width: "100%",
        "@media(min-width: 990px)": {
          backgroundColor: "rgba(251, 248, 243, 0.95)",
        },
        zIndex: 99,
      }}
    >
      <div
        css={{
          maxWidth: 1170,
          margin: "0 auto",
          "@media(min-width: 990px)": {
            display: "flex",
            justifyContent: "space-between",
          },
        }}
      >
        <div
          css={{
            position: "relative",
            height: 65,
            display: "flex",
            alignItems: "center",
            padding: "0 15px",
          }}
        >
          <CloseButton
            isOpen={isOpen}
            onClick={() => setIsOpen(prevState => !prevState)}
          />
          <a
            href={"https://www.lotusclinic.com.tw/"}
            css={{
              display: "block",
              margin: "0 auto",
              width: 175,
              "@media(min-width: 990px)": {
                margin: 0,
              },
            }}
          >
            <img src={logo} css={{ width: 175, height: 50 }} alt={"logo"} />
          </a>
        </div>
        <Navigation isOpen={isOpen} />
      </div>
    </header>
  )
}

export default Header
