import React from "react"
import { useSpring, animated } from "@react-spring/web"
import useMeasure from "../hooks/useMeasure"
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome"
import { faChevronRight } from "@fortawesome/free-solid-svg-icons"
import useResize from "../hooks/useResize"

const links = [
  {
    key: "about",
    name: "關於我們",
    href: "/about",
  },
  {
    key: "group",
    name: "醫療團隊",
    href: "https://www.lotusclinic.com.tw/doctors",
  },
  {
    key: "news",
    name: "最新消息",
    href: "https://www.lotusclinic.com.tw/news",
  },
  {
    key: "micro",
    name: "醫美微整",
    children: [
      {
        key: "picosure",
        name: "皮秒雷射",
        href: "https://www.lotusclinic.com.tw/picosure",
      },
      {
        key: "ultraformer",
        name: "海芙音波",
        href: "/ultraformer",
      },
      {
        key: "thermage",
        name: "鳳凰電波",
        href: "https://www.lotusclinic.com.tw/thermage",
      },
      {
        key: "oxygeneo",
        name: "泡泡電波",
        href: "https://www.lotusclinic.com.tw/oxygeneo",
      }
    ],
  },
  {
    key: "surgery",
    name: "整型手術",
    children: [
      {
        key: "3d",
        name: "3D縫式提眼肌手術",
        href: "https://www.lotusclinic.com.tw/3d-achalasia-up",
      },
    ],
  },
  {
    key: "fitness",
    name: "抽脂體雕",
    children: [
      {
        key: "emsculpt",
        name: "EMSCULPT 肌動減脂",
        href: "https://www.lotusclinic.com.tw/emsculpt",
      },
      {
        key: "weight-reduction",
        name: "減重專門門診",
        href: "/weight-reduction-clinic",
      },
    ],
  },
  {
    key: "woman",
    name: "女性私密",
    children: [
      {
        key: "lightsheer",
        name: "真空除毛",
        href: "https://www.lotusclinic.com.tw/lightsheer",
      },
      {
        key: "lightsheer",
        name: "G動28",
        href: "/emsella",
      },
      {
        key: "menopause",
        name: "更年期幸福門診",
        href: "https://www.lotusclinic.com.tw/menopause",
      },
      {
        key: "fontona",
        name: "FOTONA婦克漏黛娜蜜私皇后雷射",
        href: "https://www.lotusclinic.com.tw/fotona",
      }
    ],
  },
]

const itemStyle = {
  padding: `12px 0px`,
  display: "block",
}

const itemLinkStyle = {
  fontSize: 17,
  display: "block",
  lineHeight: 1.5,
  textDecoration: "none",
  "&:active, &:visited": {
    color: "inherit",
  },
  "&:hover": {
    textDecoration: "none",
    color: "#562c36",
  },
}

function SubNavigation({ items, active = false, isOpen = false }) {
  const [bind, { height: viewHeight }] = useMeasure()
  const { height, opacity } = useSpring({
    from: { height: 0, opacity: 0 },
    to: {
      height: isOpen ? viewHeight : 0,
      opacity: isOpen ? 1 : 0,
    },
  })
  const { width: screenWidth } = useResize()
  const isBigScreen = screenWidth >= 990

  return (
    <animated.div
      css={{
        overflow: "hidden",
        "@media(min-width: 990px)": {
          position: "absolute",
          "& li": {
            borderBottom: "1px solid #ddd",
          },
          "& li:last-child": {
            borderBottom: "none",
          },
        },
      }}
      style={{
        opacity: isBigScreen ? 1 : opacity,
        height: isBigScreen ? "auto" : height,
      }}
    >
      <ul css={{ margin: 0, padding: 0, listStyle: "none" }} {...bind}>
        {items.map(item => {
          return (
            <li
              key={item.key}
              css={[
                itemStyle,
                {
                  paddingLeft: 20,
                  backgroundColor: "rgba(251, 248, 243, 0.7)",
                  "@media(min-width: 990px)": {
                    padding: 0,
                  },
                },
              ]}
            >
              <a
                href={item.href}
                css={[
                  itemLinkStyle,
                  {
                    color: active ? "#bda07e" : "#34343c",
                    "@media(min-width: 990px)": {
                      height: 47,
                      lineHeight: "47px",
                      padding: "0 15px",
                      width: 200,
                    },
                  },
                ]}
              >
                {item.name}
              </a>
            </li>
          )
        })}
      </ul>
    </animated.div>
  )
}

const AnimatedIcon = animated(FontAwesomeIcon)
function NavigationItem(props) {
  const { name, href, active, children, onClick, isOpen } = props
  const iconStyle = useSpring({
    transform: isOpen ? "rotate(90deg)" : "rotate(0deg)",
  })

  return (
    <li
      css={[
        {
          borderTop: "1px solid #ebeef6",
          cursor: "pointer",
          position: "relative",
          "@media(min-width: 990px)": {
            display: "inline-block",
            padding: 0,
            border: "none",
            "&:hover": {
              "::after": {
                content: '""',
                position: "absolute",
                width: "100%",
                height: 2,
                bottom: 0,
                left: 0,
                backgroundColor: "#562c36",
              },
            },
            "&  ul": {
              opacity: 0,
              height: 0,
            },
            "&:hover ul": {
              opacity: 1,
              height: "auto",
            },
          },
        },
        itemStyle,
      ]}
      onClick={onClick}
    >
      <a
        css={[
          itemLinkStyle,
          {
            color: active ? "#bda07e" : "#34343c",
            display: "flex",
            justifyContent: "space-between",
            "@media(min-width: 990px)": {
              padding: "0 15px",
              display: "inline-block",
              height: 65,
              lineHeight: "65px",
            },
          },
        ]}
        href={href}
      >
        <span>{name}</span>
        {children && (
          <AnimatedIcon
            icon={faChevronRight}
            css={{
              fontSize: 12,
              "@media(min-width: 990px)": {
                display: "none",
              },
            }}
            style={iconStyle}
          />
        )}
      </a>
      {children}
    </li>
  )
}

function Navigation({ isOpen = false }) {
  const [openLinks, setOpenLinks] = React.useState([])
  const [windowObject, setWindowObject] = React.useState(null)

  const [bind, { height: viewHeight }] = useMeasure()
  const { width: screenWidth } = useResize()
  const { height, opacity } = useSpring({
    from: { height: 0, opacity: 0 },
    to: {
      height: isOpen ? viewHeight : 0,
      opacity: isOpen ? 1 : 0,
    },
  })
  const isBigScreen = screenWidth >= 990

  return (
    <animated.div
      css={{
        overflow: "hidden",
        backgroundColor: "rgba(251, 248, 243, 0.7)",
        "@media(min-width: 990px)": {
          overflow: "unset",
        },
      }}
      style={{
        opacity: isBigScreen ? 1 : opacity,
        height: isBigScreen ? "auto" : height,
      }}
    >
      <ul
        {...bind}
        css={{
          display: "block",
          listStyle: "none",
          margin: 0,
          padding: "0 15px",
          "@media(min-width: 990px)": {
            height: 65,
            display: "flex",
            alignItems: "center",
            padding: 0,
          },
        }}
      >
        {links.map(item => {
          let isActive = false
          const currentPath =
            windowObject?.location?.href.toLowerCase() || "server-side render"
          if (
            currentPath === item.href?.toLowerCase() ||
            item.children?.find(c => c.href?.toLowerCase() === currentPath)
          ) {
            isActive = true
          }
          return (
            <NavigationItem
              key={item.key}
              href={item.href}
              name={item.name}
              active={isActive}
              onClick={() => {
                if (item.children) {
                  setOpenLinks(currentLinks => {
                    const idx = currentLinks.indexOf(item.key)
                    if (idx > -1) {
                      return currentLinks.filter(l => !(l === item.key)) || []
                    } else {
                      return [...currentLinks, item.key]
                    }
                  })
                }
              }}
              isOpen={openLinks.indexOf(item.key) > -1}
            >
              {item.children && (
                <SubNavigation
                  items={item.children}
                  isOpen={openLinks.indexOf(item.key) > -1}
                />
              )}
            </NavigationItem>
          )
        })}
      </ul>
    </animated.div>
  )
}

export default Navigation
